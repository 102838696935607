import {
  isDesktop
} from './helpers';
import {
  gsap
} from 'gsap';
import {
  ScrollToPlugin
} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin);
gsap.config({
  autoKillThreshold: 1
});

const hamburgerWrapper = document.querySelector(".hamburger__wrapper");
const hamburger = document.querySelector(".hamburger");
const body = document.querySelector('body');
const navigation = document.querySelector('.navigation');

/**
 * Toggle menu on menu-icon click
 */
hamburgerWrapper.addEventListener("click", function () {
  toggleMenu();
});

/**
 * Toggle menu on mobile if click on link with Anchor
 */
const navigationItems = document.querySelectorAll('.menu-item a');
navigationItems.forEach((anchor) => {
  anchor.addEventListener('click', (e) => {
    if (e.target.hash) {
      toggleMenu();
    } else {
      return true;
    }
  })
});

/**
 * Toggle mobile menu function
 */
function toggleMenu() {
  if (!isDesktop()) {
    hamburger.classList.toggle("is-active");
    body.classList.toggle('modal-open');
    body.classList.toggle('navigation-is-open');
    navigation.classList.toggle('navigation__is-open');
  }
}

/**
 * Fixed navigation and call-us button
 */
const fixedNav = document.querySelector('.js-nav');
const callUs = document.querySelector('.call-us');

window.addEventListener('scroll', function () {
  let scrollPosition = window.scrollY;
  let offset = isDesktop() ? 400 : 200;
  if (scrollPosition >= offset) {
    fixedNav.classList.add('js-nav-scrolled');
  } else {
    fixedNav.classList.remove('js-nav-scrolled');
  }
});

/**
 * Scroll to Anchor
 */

document.addEventListener( 'click', (e) => {
  if (e.target.nodeName === 'A') {
    if (e.target.hash) {

      e.preventDefault();

      gsap.to(window, {
        duration: 0.3,
        scrollTo: e.target.hash,
        ease: 'power1.easeIn'
      })

    } else {
      return true;
    }

  } else  {
    let parent = e.target.parentNode;
    let self = e.target;
    let childs = e.target.childNodes;

    if (parent.nodeName === 'A') {
      if (parent.hash) {
        e.preventDefault();
        gsap.to(window, {
          duration: 0.3,
          scrollTo: parent.hash,
          ease: 'power1.easeIn'
        })
      } else {
        return true;
      }
    } else if (childs.length === 1) {
      let child = childs[0];
      if (child.nodeName === 'A') {
        if (child.hash) {
          e.preventDefault();
          gsap.to(window, {
            duration: 0.3,
            scrollTo: child.hash,
            ease: 'power1.easeIn'
          })
        }
        else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      let target = e.target.closest('a');
      if (target) {
        if (target.nodeName === 'A') {
          if (target.hash) {
            e.preventDefault();
            gsap.to(window, {
              duration: 0.3,
              scrollTo: target.hash,
              ease: 'power1.easeIn'
            })
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  } 
});