/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'

/**
 * Web standards
 */
import './menu'
import './lazyload'
import './cookieconsent'

/**
 * Scripts which doesn't need dynamic import
 */
import {  Modal } from 'bootstrap';

/**
 * Dynamic import scripts
 */
const elements = [
  '.form-wrapper',
  '.block-slider',
  '.retailers-swiper'
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      // Example library without init:
      if (element === '.form-wrapper') {
        const Forms = import('./forms')
      }

      if (element === '.block-slider') {
        const Forms = import('./slider')
      }

      if (element === '.retailers-swiper') {
        const Forms = import('./retailers')
      }
      
      // Example library with init:
      // if (element === '[data-aos]' || element === '.aos-element') {
      //   const AOS = import('aos').then(AOS => {
      //     AOS.init();
      //   });
      // }
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});

(function () {
  if (typeof MutationObserver === 'undefined') {
      return;
  }
  var fixFontDisplay = function () {
      // inject font-display option into typekit fonts
      var styles = document.getElementsByTagName('style');
      for (var i = 0; i < styles.length; i++) {
          if (
              styles[i].innerText
              && styles[i].innerText.indexOf('minion-pro') !== -1
              && styles[i].innerText.indexOf('@font-face{font-display:swap;') === -1
          ) {
              styles[i].innerText = styles[i].innerText
                      .split('@font-face{').join('@font-face{font-display:swap;');
          }
      }
  };
  var observer = new MutationObserver(function (mutationsList, observer) {
      for (var i = 0; i < mutationsList.length; i++) {
          fixFontDisplay();
      }
  });
  observer.observe(
      document.getElementsByTagName('head')[0],
      {attributes: false, childList: true, subtree: false}
  );
  window.fixFontObserver = observer;
})();